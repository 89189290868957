import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable()

export class CommonShareService {
  private toggle_sidebar = new Subject<boolean>();
  constructor() {

  }

  public toggle_device_sidebar(toggle: boolean): void {
    this.toggle_sidebar.next(toggle);
  }

  public sidebar_toggle_event(): Observable<any> {
    return this.toggle_sidebar.asObservable();
  }
}
